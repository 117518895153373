.centerItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > .table {
  height: auto;
  text-align: center;
  margin-top: 1rem;
}

.col-md-3 {
  border: 0.2px solid #0000004a;
  padding: 0;
}

.col-md-3 > h5 {
  background-color: #80808054;
  padding: 10px;
}

button {
  width: 120px;
  background-color: #ffe000 !important;
  border: none !important;
  color: black !important;
}

button:hover {
  background-color: #aca35f !important;
}

/*--------------- style for card ------------*/

.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: #ffe000;
  border: none;
}
.card:hover {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  transition: box-shadow 0.2s ease-in-out;
}
#processing .card .card-header::after {
  content: " - ⏲️";
}
#approved .card .card-header::after {
  content: " - 👍";
}
#done .card .card-header::after {
  content: " - ✅";
}
